import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Layout from '../../layouts/Default';
import config from '../../config';
import Section from '../../components/Section';
import FeatureSection from '../../components/FeatureSection';
import StudentSupport from '../../components/StudentSupport';
import ClassroomFeatures from '../../components/ClassroomFeatures';
import useContentLanguageSwitcher from '../../hooks/useContentLanguageSwitcher';
import content from '../../../content/features/index';

const EngagementImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  display: 'block',
  width: '800px',
}));

const section = (theme) => ({
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(8),
  },
  paddingBottom: theme.spacing(8),
});

export const FeaturesPageTemplate = ({
  helmet,
  content,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {helmet || ''}
      <Section
        sx={section}
      >
        <FeatureSection
          {...content.features}
        />
      </Section>
      <Section
        sx={section}
      >
        <StudentSupport {...content.support} />
      </Section>
      <Section
        sx={section}
      >
        <ClassroomFeatures {...content.classrooms} />
      </Section>
      <Section
        sx={section}
      >
        <Grid>
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                fontSize: '48px',
                lineHeight: '65px',
                marginTop: '4rem',
              },
              fontSize: '25px',
              lineHeight: '40px',
              marginTop: '1rem',
              marginBottom: '3rem',
            })}
            variant="h2"
            component="h2"
            textAlign={'center'}
            fontWeight={'bold'}
          >
            {content.engagement.title}
          </Typography>
          {
            content.engagement.sections.map((section, index) => (
              <Grid
                key={`engagement-section-${index}`}
                container
                justifyContent={'center'}
                spacing={4}
                padding={1}
                direction={
                  // eslint-disable-next-line
                  isSmallScreen
                    ? 'column-reverse' : (section.imagePosition === 'right' ? 'row-reverse' : 'row')
                }
                sx={(theme) => ({
                  [theme.breakpoints.up('md')]: {
                    marginBottom: 5,
                  },
                  marginBottom: 1,
                })}
              >
                <Grid item xs={12} sm={6}>
                  <EngagementImg
                    src={section.image}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={(theme) => ({
                      [theme.breakpoints.up('md')]: {
                        fontSize: '30px',
                      },
                      fontSize: '20px',
                    })}
                    variant="h6"
                    component="h6"
                    textAlign={'start'}
                    color={'primary'}
                    margin={1}
                  >
                    {section.title}
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      [theme.breakpoints.up('md')]: {
                        lineHeight: 1.75,
                      },
                    })}
                    variant="body1"
                    component="p"
                    margin={1}
                    textAlign={'start'}
                  >
                    {section.description}
                  </Typography>
                </Grid>
              </Grid>
            ))
          }
          <Grid container justifyContent={'center'}>
            <Button
              sx={{
                color: 'white',
                backgroundColor: 'primary',
                padding: 1.5,
                paddingLeft: 3,
                paddingRight: 3,
                margin: 5,
              }}
              variant='contained'
              component='a'
              href={`${config.APP_URL}/?action=signUp`}
            >
              {t('get_started')}
            </Button>
          </Grid>
        </Grid>
      </Section>
    </>
  );
};

FeaturesPageTemplate.propTypes = {
  helmet: PropTypes.object,
  content: PropTypes.object,
};

function Features(props) {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
    },
  } = props;

  const [featuresPageData] = useContentLanguageSwitcher({ content });

  return (
    <Layout>
      <FeaturesPageTemplate
        helmet={
          <Helmet title={`Features | ${title}`} />
        }
        content={featuresPageData}
      />
    </Layout>

  );
}

Features.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

export default Features;

export const featuresPageQuery = graphql`
  query FeaturesQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
