import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import MobileStepper from '@mui/material/MobileStepper';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const rootStyles = {
  marginBottom: '5px',
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles((theme) => ({
  step: {
    height: 'calc(100vh - 116px)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  stepInfo: {
    maxWidth: 400,
  },
  mobileStepper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));

function ClassroomFeatures({
  title, subtitle, accordions,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedAccordion, setSelectedAccordion] = useState(accordions[0]);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleAccordionChange = (acc) => () => {
    setSelectedAccordion(acc);
  };

  const maxSteps = accordions.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
        <div>
            <Typography
                sx={(theme) => ({
                  [theme.breakpoints.up('md')]: {
                    fontSize: '44px',
                    lineHeight: '56px',
                    marginTop: '2rem',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '600px',
                  },
                  fontWeight: 'bold',
                  fontSize: '26px',
                  lineHeight: '32px',
                  marginTop: '1rem',
                })}
                variant="h2"
                component="h2"
                textAlign={'center'}
            >
                {title}
            </Typography>
            <Typography
                sx={(theme) => ({
                  [theme.breakpoints.up('md')]: {
                    fontSize: '24px',
                    lineHeight: '32px',
                  },
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '24px',
                  marginTop: '1rem',
                })}
                variant="h2"
                component="h2"
                align="center"
            >
                {subtitle}
            </Typography>
            <Grid
                container
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                  marginTop: '4rem',
                })}
                spacing={2}
            >
                <Grid item xs={12} sm={6} justifyContent={'right'} marginTop={15}>
                    {
                        accordions.map((accordion, index) => (
                            <Accordion
                                key={`accordion-${index}`}
                                expanded={selectedAccordion.title === accordion.title}
                                onChange={handleAccordionChange(accordion)}
                                classes={{ root: rootStyles }}

                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={(theme) => ({
                                      backgroundColor: selectedAccordion.title === accordion.title
                                        ? `${theme.palette.primary.main}26` : null,
                                      color: selectedAccordion.title === accordion.title
                                        ? theme.palette.primary.main : null,
                                    })}
                                >
                                    <Typography
                                        sx={{
                                          fontWeight: 'bold',
                                          fontSize: 20,
                                        }}
                                        variant="h6"
                                    >
                                        {accordion.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={() => ({
                                          lineHeight: 1.75,
                                        })}
                                        variant="body1"
                                        component="p"
                                    >
                                        {accordion.description}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </Grid>
                <Grid item xs={12} sm={6} textAlign="right">
                {<img width={500} src={selectedAccordion.image} />}
                </Grid>
            </Grid>
            <Box
                sx={(theme) => ({
                  [theme.breakpoints.up('md')]: {
                    display: 'none',
                  },
                })}
            >
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    interval={5000}
                >
                    {
                        accordions.map((accordion, index) => (
                            <div
                                className={classes.step}
                                key={`student-support-step-${index}`}
                            >
                                <div className={classes.stepInfo}>
                                    <img className={classes.img} src={accordion.image} />
                                    <Typography variant="h5" gutterBottom>
                                        {accordion.title}
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>{accordion.description}</Typography>
                                </div>
                            </div>
                        ))
                    }
                </AutoPlaySwipeableViews>
                <div>
                    <MobileStepper
                        className={classes.mobileStepper}
                        steps={maxSteps}
                        position="static"
                        variant="dots"
                        activeStep={activeStep}
                        alignItems="center"
                    />
                </div>
            </Box>
        </div>
  );
}

ClassroomFeatures.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  accordions: PropTypes.array.isRequired,
};

export default ClassroomFeatures;
