/* eslint-disable camelcase */
import de from './features.de.yaml';
import en from './features.en.yaml';
import en_US from './features.en_US.yaml';
import es from './features.es.yaml';
import ga from './features.ga.yaml';
import it from './features.it.yaml';
import pt from './features.pt.yaml';
import fr from './features.fr.yaml';

export default {
  de,
  en,
  en_US,
  es,
  ga,
  it,
  pt,
  fr,
};
