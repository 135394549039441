import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function ButtonSection({
  buttons, setMobileImage,
}) {
  const [selectedButton, setSelectedButton] = useState(buttons[0]);

  const handleChange = (event, newValue) => {
    const buttonIndex = buttons.findIndex((b) => b.label === newValue);
    if (buttonIndex > -1) {
      const button = buttons[buttonIndex];
      setSelectedButton(button);
      setMobileImage(button.image);
    }
  };

  return (
        <>
            <Box
                sx={(theme) => ({
                  width: '100%',
                  typography: 'body1',
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                })}
            >
                <TabContext value={selectedButton.label}>
                    <Box sx={{ marginTop: '1rem' }}>
                        <TabList
                            onChange={handleChange}
                        >
                            {
                                buttons.map((button) => (
                                    <Button
                                        key={`section-tab-button-${button.label}`}
                                        onClick={(e) => {
                                          handleChange(e, button.label);
                                        }}
                                        variant={selectedButton.label === button.label ? 'contained' : 'outlined'}
                                        sx={(theme) => ({
                                          marginRight: theme.spacing(2),
                                        })}
                                    >
                                        {button.label}
                                    </Button>
                                ))
                            }
                        </TabList>
                        {
                            buttons.map((button) => (
                                <TabPanel
                                    key={`section-tab-button-${button.label}-description`}
                                    value={button.label}
                                    sx={(theme) => ({
                                      padding: theme.spacing(3, 0),
                                    })}
                                >
                                    <Box>
                                        <Typography
                                            sx={(theme) => ({
                                              [theme.breakpoints.down('md')]: {
                                                display: 'none',
                                              },
                                              lineHeight: 1.75,
                                            })}
                                            variant="body1"
                                            component="p"
                                        >
                                            {button.description}
                                        </Typography>
                                    </Box>
                                </TabPanel>
                            ))
                        }
                    </Box>
                </TabContext>
            </Box>
            <Box
                sx={(theme) => ({
                  width: '100%',
                  [theme.breakpoints.up('md')]: {
                    display: 'none',
                  },
                })}
            >
                {
                    buttons.map((button) => (
                        <Grid
                            container
                            key={`section-tab-button-mobile-${button.label}`}
                            sx={(theme) => ({
                              marginBottom: theme.spacing(2),
                            })}
                        >
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="h6"
                                    component="h6"
                                    color={'primary'}
                                    gutterBottom
                                >
                                    {button.label}
                                </Typography>
                                <Typography
                                    sx={() => ({
                                      lineHeight: 1.75,
                                    })}
                                    variant="body1"
                                    component="p"
                                >
                                    {button.description}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ marginTop: '1rem', textAlign: 'center' }}>
                                    <img width={'200px'} src={button.image} />
                                </Box>
                            </Grid>
                        </Grid>
                    ))
                }
            </Box>
        </>
  );
}

ButtonSection.propTypes = {
  buttons: PropTypes.array.isRequired,
  setMobileImage: PropTypes.func.isRequired,
};

function ListItem({
  listItem, selectedListItem, handleChange,
}) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
        <ListItemButton
            selected={isLargeScreen && listItem.label === selectedListItem.label}
            onClick={() => handleChange(listItem)}
        >
            <ListItemIcon>
                <DoneOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={listItem.label} />
        </ListItemButton>
  );
}

ListItem.propTypes = {
  listItem: PropTypes.object.isRequired,
  selectedListItem: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

function ListSection({
  listItems, setMobileImage,
}) {
  const [selectedListItem, setSelectedListItem] = useState(listItems[0]);

  const handleChange = (listItem) => {
    setSelectedListItem(listItem);
    setMobileImage(listItem.image);
  };

  const leftList = listItems.filter((_, i) => i % 2 === 0);
  const rightList = listItems.filter((_, i) => i % 2 === 1);

  return (
        <Box
            sx={() => ({
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            })}
        >
            <Grid container>
                <Grid xs={12} md={6}>
                    <List
                        component="nav"
                        disablePadding
                    >
                        {
                            leftList.map((listItem) => (
                                <ListItem
                                    key={`section-tab-list-item-${listItem.label}`}
                                    listItem={listItem}
                                    selectedListItem={selectedListItem}
                                    handleChange={handleChange}
                                />
                            ))
                        }
                    </List>
                </Grid>
                <Grid xs={12} md={6}>
                    <List
                        component="nav"
                        disablePadding
                    >
                        {
                            rightList.map((listItem) => (
                                <ListItem
                                    key={`section-tab-list-item-${listItem.label}`}
                                    listItem={listItem}
                                    selectedListItem={selectedListItem}
                                    handleChange={handleChange}
                                />
                            ))
                        }
                    </List>
                </Grid>
            </Grid>
        </Box>
  );
}

ListSection.propTypes = {
  setMobileImage: PropTypes.func.isRequired,
  listItems: PropTypes.array.isRequired,
};

function FeatureSection({
  title, subtitle, sections,
}) {
  const [mobileImage, setMobileImage] = React.useState(sections[0].buttons[0].image);
  const [selectedSection, setSelectedSection] = useState(sections[0]);

  const handleSectionChange = (_, newValue) => {
    const sectionIndex = sections.findIndex((s) => s.tab === newValue);
    if (sectionIndex > -1) {
      const section = sections[sectionIndex];
      setSelectedSection(section);
      if (section.buttons) {
        setMobileImage(section.buttons[0].image);
      } else if (section.listItems) {
        setMobileImage(section.listItems[0].image);
      }
    }
  };

  return (
        <React.Fragment>
            <Typography
                sx={(theme) => ({
                  [theme.breakpoints.up('md')]: {
                    fontSize: '44px',
                    lineHeight: '56px',
                    marginTop: '4rem',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '600px',
                  },
                  fontWeight: 'bold',
                  fontSize: '26px',
                  lineHeight: '32px',
                  marginTop: '1rem',
                })}
                variant="h1"
                component="h1"
                align="center"
            >
                {title}
            </Typography>
            <Typography
                sx={(theme) => ({
                  [theme.breakpoints.up('md')]: {
                    fontSize: '24px',
                    lineHeight: '32px',
                  },
                  fontWeight: 'normal',
                  fontSize: '18px',
                  lineHeight: '24px',
                  marginTop: '1rem',
                })}
                variant="h2"
                component="h2"
                align="center"
            >
                {subtitle}
            </Typography>`
            <Grid
                container
                sx={(theme) => ({
                  [theme.breakpoints.up('md')]: {
                    marginTop: '4rem',
                  },
                  marginTop: '2rem',
                })}
            >
                <Grid item xs={12} md={8}>
                    <Box>
                        <TabContext value={selectedSection.tab}>
                            <Box sx={{ marginLeft: '1.5rem' }}>
                                <TabList
                                    onChange={handleSectionChange}
                                    sx={{
                                      '& .MuiTabs-indicator': {
                                        height: 4,
                                      },
                                    }}
                                >
                                    {
                                        sections.map((section) => (
                                            <Tab
                                                key={`section-tab-${section.tab}`}
                                                label={section.tab}
                                                value={section.tab}
                                            />
                                        ))
                                    }
                                </TabList>
                            </Box>
                            {
                                sections.map((section) => (
                                    <TabPanel
                                        key={`section-tab-panel-${section.tab}`}
                                        value={section.tab}
                                        sx={(theme) => ({
                                          [theme.breakpoints.up('md')]: {
                                            maxWidth: 700,
                                          },
                                        })}
                                    >
                                        <Typography
                                            fontWeight={'bold'}
                                            fontSize={20}
                                            maxWidth={400}
                                            gutterBottom
                                        >
                                            {section.title}
                                        </Typography>
                                        <Typography
                                            sx={(theme) => ({
                                              padding: theme.spacing(2, 0),
                                              lineHeight: 1.75,
                                            })}
                                            gutterBottom
                                            variant="body1"
                                            component="p"
                                        >
                                            {section.description}
                                        </Typography>
                                        {
                                            section.buttons && section.buttons.length > 0
                                            && <ButtonSection
                                                buttons={section.buttons}
                                                setMobileImage={setMobileImage}
                                            />
                                        }
                                        {
                                            section.listItems && section.listItems.length > 0
                                            && <ListSection
                                                listItems={section.listItems}
                                                setMobileImage={setMobileImage}
                                            />
                                        }

                                    </TabPanel>
                                ))
                            }
                        </TabContext>
                    </Box>
                </Grid>
                <Grid item md={4}
                    sx={(theme) => ({
                      [theme.breakpoints.down('md')]: {
                        display: 'none',
                      },
                    })}
                >
                    <Box>
                        <img width={275} src={mobileImage} />
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
  );
}

FeatureSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
};

export default FeatureSection;
